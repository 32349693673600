<template>
  <div  class="body">
     <div>
       <van-form @submit="onSubmit">
         <!-- 允许输入正整数，调起纯数字键盘 -->
         <van-search
             v-model="waterCode"
             shape="round"
             show-action
             background="#4fc08d"
             type="digit"
             @search="onSearch"
             placeholder="请输入缴费户号8位或12位数字"
         >
           <template #action>
             <div @click="onSearch">搜索</div>
           </template>
         </van-search>

         <van-notice-bar
             left-icon="volume-o"
             text="注: 晚上23:00-凌晨2:00点为系统对账时间，暂不支持缴费，谢谢!"
         />
         <van-field
             v-model="from.waterCode"
             label="缴费户号:"
             placeholder="缴费户号"
             disabled
         />
         <van-field
             v-model="from.customerName"
             label="缴费户名:"
             placeholder="缴费户名"
             disabled
         />
         <van-field
             v-model="from.customerAddr"
             label="用户地址:"
             placeholder="用户地址"
             disabled
         />
         <van-field
             v-model="from.chargingUnit"
             label="收费单位:"
             placeholder="收费单位"
             disabled
         />
         <van-field
             v-model="from.payAmount"
             label="应缴金额:"
             placeholder="应缴金额"
             disabled
         />
         <van-field
             v-model="from.balance"
             label="结余金额:"
             placeholder="结余金额"
             disabled
         />
         <van-field
             v-model="payAmount"
             clearable
             error
             required
             label="输入缴费金额: ￥"
             placeholder="请输入缴费金额"
             label-width="130px"
             size="large"
             right-icon="after-sale"
         />
         <div style="height:10px;width:100%"></div>
         <van-button round type="primary" size="large">立即缴费</van-button>
       </van-form>
      <!--<van-popup v-model="show">内容</van-popup>-->
     </div>
    <div class="connect" @click="jumpPayment">查询水费记录</div>
  </div>
</template>

<script>
import payhead from '../components/payhead'
import request from "@/api/index.js";
import {appId} from '@/settings.js'
export default {
  name: '',
  components: {
    payhead
  },
  props: {},
  data() {
    return {
      show:false,
      code:'',
      payAmount:'',
      waterCode:'',
      appId:appId,
      from:{
        userName:'',
        waterCode:'',
      },
      token:'',
    }
  },
  computed: {

  },
  watch: {
  },
  created() {
     this.code=this.getUrlCode().code
     this.getToken()
  },
  mounted() {

  },
  methods: {
    getToken(){
      request.get('/pay/waterFee/token',{params:{code:this.code}}).then(res=>{
        this.token=res.data
        localStorage.setItem("orderToken",this.token)
      }).catch(err=>{
        alert(err.message)
      })
    },
    onSearch(value){
      if (!this.waterCode){
        this.$dialog({ message: '请输入正确的水表编号！' });
        return
      }
      request.get('/pay/waterFee/query',{params:{waterCode:this.waterCode}}).then(res=>{
        this.from=res.data
        this.from.payAmount=Number(this.from.payAmount)/100
        this.from.balance=Number(this.from.balance)/100
      }).catch(err=>{
        this.$dialog({ message: '水表信息有误！' });
      })
      /*request.get('/article/article/list', { params: {categoryId: 12} }).then(res => {
        console.log(res)
      })*/
    },
    onSubmit(values){
      if (!this.from.waterCode){
        this.$dialog({ message: '请输入正确的水表编号！' });
        return
      }
      if (this.payAmount<this.from.payAmount){
        this.$dialog({ message: '缴费金额不能小于应缴金额！' });
        return
      }
      let data={
        payAmount:this.payAmount,
        waterCode:this.from.waterCode,
        token:this.token?this.token:localStorage.getItem("orderToken"),
      }
      request.post('/pay/waterFee/order',data).then(res=>{
        if (res.code=='200' || res.code==200){
          let da=eval("("+res.data.payData+")")
          console.log(JSON.parse(res.data.payData))
          WeixinJSBridge
              .invoke(
                  'getBrandWCPayRequest',
                  {
                    "appId": da.appId, //公众号名称，由商户传入
                    "timeStamp": da.timeStamp, //时间戳，自1970年以来的秒数
                    "nonceStr": da.nonceStr, //随机串
                    "package": da.package,
                    "signType": da.signType, //微信签名方式:
                    "paySign": da.paySign
                    //微信签名
                  },
                  function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                      alert("支付成功");
                    }
                    if (res.err_msg == "get_brand_wcpay_request:cancel") {
                      alert("交易取消");
                    }
                    if (res.err_msg == "get_brand_wcpay_request:fail") {
                      alert("支付失败");
                    }
                  });
        }else {
          alert("支付错误")
        }
      })
      this.show = true;
      /*if (this.payAmount<this.from.payAmount){
        this.$dialog({ message: '缴费金额不能小于应缴金额！' });
      }else {
        orderRequest.post('',{})
      }*/
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    jumpPayment(){
      this.$router.push({path: '/historywater', query: {waterCode:this.waterCode}})
    }
  },
}
</script>
<style lang='scss' scoped>
.body{

}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
}
</style>
